import React, { useState, useEffect } from "react";
import './assets/css/colors/color.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

import { Header } from "./components/header/Header";

import { Home } from "./pages/home/Home";
import { About } from "./pages/about/About";
import { Salawats } from "./pages/salawats/Salawats";
import { Salawat } from "./pages/salawat/Salawat";
import { Tasbeeh } from "./pages/tasbeeh/Tasbeeh"

import { GoshaEDurood } from "./pages/goshaedurood/GoshaEDurood";
import { Scholars } from "./pages/scholars/Scholars";
import { Contact } from "./pages/contact/Contact";

import { Footer } from "./components/footer/Footer";

const App = () => {
  const [panel, setPanel] = useState(false);

  const [darkMode, setDarkMode] = useState(false);

  // Retrieve the saved theme from localStorage or default to null
  const [activeColor, setActiveColor] = useState(() => localStorage.getItem('themeColor') || 'color');

  // Function to set a new value for the CSS variable
  const setCSSVariable = (variableName, value) => {
    console.log(`Setting ${variableName} to ${value}`);
    document.documentElement.style.setProperty(variableName, value);
    // Log the current value to ensure it's being updated
    const currentValue = getComputedStyle(document.documentElement).getPropertyValue(variableName);
    console.log(`Updated value of ${variableName}: ${currentValue}`);
  };

  useEffect(() => {
    if (activeColor) {
      localStorage.setItem('themeColor', activeColor); // Save the current theme to localStorage

      if (activeColor === 'color') {
        setDarkMode(false);
        setCSSVariable('--primary-theam-bg', 'white');
        setCSSVariable('--primary-theam-color', '#000');
        setCSSVariable('--secondary-theam-color', 'white');
      }
      if (activeColor === 'color2') {
        setDarkMode(true);
        setCSSVariable('--primary-theam-bg', 'white');
        setCSSVariable('--primary-theam-color', '#ffffff');
        setCSSVariable('--secondary-theam-color', '#000');
      }
      if (activeColor === 'color3') {
        setCSSVariable('--primary-theam-bg', 'white');
        setCSSVariable('--primary-theam-color', '#38ab1e');
        setCSSVariable('--secondary-theam-color', '#000');
      }
      if (activeColor === 'color4') {
        setCSSVariable('--primary-theam-bg', 'white');
        setCSSVariable('--primary-theam-color', '#f64243');
        setCSSVariable('--secondary-theam-color', '#000');

      }
      if (activeColor === 'color5') {
        setCSSVariable('--primary-theam-bg', 'white');
        setCSSVariable('--primary-theam-color', '#a00d09');
        setCSSVariable('--secondary-theam-color', '#000');
      }
      if (activeColor === 'color6') {
        setCSSVariable('--primary-theam-bg', 'white');
        setCSSVariable('--primary-theam-color', '#0383ca');
        setCSSVariable('--secondary-theam-color', '#000');

      }
      if (activeColor === 'color7') {
        setCSSVariable('--primary-theam-bg', '#938640');
        setCSSVariable('--primary-theam-color', '#938640');
        setCSSVariable('--secondary-theam-color', '#000');
      }
      if (activeColor === 'color8') {
        setCSSVariable('--primary-theam-bg', '#b57c00');
        setCSSVariable('--primary-theam-color', '#b57c00');
        setCSSVariable('--secondary-theam-color', '#000');
      }
    }
  }, [activeColor, darkMode]);

  return (
    <>
      <main className={`${darkMode ? 'dark-mode' : ''}`}>
        <button onClick={() => setDarkMode(!darkMode)} className='btn-mode thm-play-btn '>
          <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
        </button>

        <div className={`sidepanel ${panel ? 'show' : ''}`}>
          <span onClick={() => setPanel(!panel)}>
            <i className="fa fa-cog fa-spin"></i>
          </span>
          <div className="color-picker">
            <h3>Choose Your Color</h3>
            <button onClick={() => setDarkMode(!darkMode)} className='btn-mode thm-play-btn'>
              <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
            </button>
            <a href={void (0)} className={`color ${activeColor === 'color' ? 'applied' : ''}`} onClick={() => setActiveColor('color')} style={{ backgroundColor: 'black' }}>
              <i aria-hidden="true"></i>
            </a>
            <a href={void (0)} className={`color2 ${activeColor === 'color2' ? 'applied' : ''}`} onClick={() => setActiveColor('color2')} style={{ backgroundColor: 'white' }}>
              <i aria-hidden="true"></i>
            </a>
            <a href={void (0)} className={`color3 ${activeColor === 'color3' ? 'applied' : ''}`} onClick={() => setActiveColor('color3')}>
              <i aria-hidden="true"></i>
            </a>
            <a href={void (0)} className={`color4 ${activeColor === 'color4' ? 'applied' : ''}`} onClick={() => setActiveColor('color4')}>
              <i aria-hidden="true"></i>
            </a>
            <a href={void (0)} className={`color5 ${activeColor === 'color5' ? 'applied' : ''}`} onClick={() => setActiveColor('color5')}>
              <i aria-hidden="true"></i>
            </a>
            <a href={void (0)} className={`color6 ${activeColor === 'color6' ? 'applied' : ''}`} onClick={() => setActiveColor('color6')}>
              <i aria-hidden="true"></i>
            </a>
            <a href={void (0)} className={`color ${activeColor === 'color7' ? 'applied' : ''}`} onClick={() => setActiveColor('color7')} >
              <i aria-hidden="true"></i>
            </a>
            <a href={void (0)} className={`color ${activeColor === 'color8' ? 'applied' : ''}`} onClick={() => setActiveColor('color8')} >
              <i aria-hidden="true"></i>
            </a>
          </div>
        </div>

        <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/salawats" element={<Salawats />} />
            <Route exact path="/gosha-e-durood" element={<GoshaEDurood />} />
            <Route exact path="/tasbeeh" element={<Tasbeeh />} />
            <Route exact path="/scholars" element={<Scholars />} />
            <Route exact path="/salawat/:slug" element={<Salawat />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </Router>
      </main>
    </>
  );
};

export default App;
